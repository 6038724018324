import { db } from '@/config/firebase';


async function saveTicket(ticketObj) {
    let query;
    if (ticketObj.id) {
        query = db.collection('tickets').doc(ticketObj.id);
    } else {
        query = db.collection('tickets').doc();
        ticketObj.id = query.id;
    }

    return query.set(ticketObj);
}

function generateQuery(filter) {
    let query = db.collection("tickets");

    if (filter.fromTimestamp && filter.fromTimestamp > 0) {
        query = query.where("dateCreated", ">=", filter.fromTimestamp);
    }

    if (filter.toTimestamp && filter.toTimestamp > 0) {
        query = query.where("dateCreated", "<=", filter.toTimestamp);
    }

    if (filter.regionName && typeof filter.regionName === 'string' && filter.regionName.length > 0) {
        query = query.where("branchDetails.region", "==", filter.regionName);
    }

    if (filter.areaName && typeof filter.areaName === 'string' && filter.areaName.length > 0) {
        query = query.where("branchDetails.area", "==", filter.areaName);
    }

    if (filter.branchCode && typeof filter.branchCode === 'string' && filter.branchCode.length > 0) {
        query = query.where("branchDetails.branchCode", "==", filter.branchCode);
    }

    if (filter.ticketId && filter.ticketId.length > 0) {
        query = query.where("ticketId", "==", filter.ticketId);
    }

    if (filter.status && filter.status.length > 0) {
        query = query.where("status", "==", filter.status);
    }

    query = query.orderBy("dateCreated", "desc");

    return query;
}

async function getTickets(filterBy) {
    let queryRef = generateQuery(filterBy);

    return queryRef.get().then(function (querySnapshot) {
        let tickets = {}
        querySnapshot.forEach(function (doc) {
            let ticket = doc.data();
            ticket['id'] = doc.id;
            tickets[doc.id] = ticket;
        });
        return {
            isSuccess: true,
            tickets: tickets
        };
    }).catch((error) => {
        return {
            isSuccess: false,
            error: error
        };
    })
}

async function getTicketListener(filter, callback) {
    let query = generateQuery(filter);
    let listener = query.onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
            callback(change.type, change.doc.data());
        });
    });
    return listener;
}

export default {
    saveTicket,
    getTickets,
    getTicketListener
}