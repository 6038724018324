<template>
	<span>
		<span v-if="ticket.status === 'Pending'">
			<b-badge variant="secondary">{{ ticket.status }}</b-badge>
		</span>
		<span v-else-if="ticket.status === 'On-Going'">
			<b-badge variant="success">{{ ticket.status }}</b-badge>
		</span>
		<span v-else-if="ticket.status === 'Done'">
			<b-badge variant="primary">{{ ticket.status }}</b-badge>
		</span>
		<span v-else-if="ticket.status === 'Cancelled'">
			<b-badge variant="danger">{{ ticket.status }}</b-badge>
		</span>
	</span>
</template>

<script>
export default {
	name: 'user-ticket-status',
	props: {
		ticket: {
			type: Object,
			required: true
		}
	}
};
</script>