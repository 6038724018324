<template>
    <div class="animated fadeIn">
        <b-card>
            <b-card-title><i class="fa fa-ticket"></i> User Tickets</b-card-title>
            <b-card-sub-title>For managing all user ticket concerns per company</b-card-sub-title>
            <b-container fluid class="mt-4">
                <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

                <b-row class="mt-2">
                    <b-col sm="12" md="3" lg="3">
                        <b-button v-b-popover.hover.right="'Toggle to show/hide filter options'" v-b-toggle.collapse-1
                            class="filter">
                            FILTER OPTIONS
                        </b-button>
                    </b-col>
                    <b-col sm="12">
                        <!-- Collapsible Filter Options -->
                        <b-collapse id="collapse-1" class="mt-2">
                            <b-card>
                                <b-row>
                                    <b-col md="12" sm="12" class="mt-1 mb-2">
                                        <b>FILTER OPTIONS</b>
                                    </b-col>
                                </b-row>

                                <b-row no-gutters>
                                    <b-col lg="4" md="6" sm="12" class="mr-4">
                                        <b-form-group label="Date From">
                                            <b-form-datepicker name="Date From" v-model="filterBy.dateFrom" locale="en"
                                                reset-button label-reset-button="Clear" :date-format-options="{
                                                    year: 'numeric',
                                                    month: 'short',
                                                    day: '2-digit',
                                                    weekday: 'short',
                                                }" :date-disabled-fn="dateFromDisabled" v-validate="'required'" />
                                            <span v-show="errors.has('Date From')" class="help-block">
                                                {{ errors.first('Date From') }}
                                            </span>
                                        </b-form-group>
                                    </b-col>
                                    <b-col lg="4" md="6" sm="12" class="mr-4">
                                        <b-form-group label="Date To">
                                            <b-form-datepicker name="Date To" v-model="filterBy.dateTo" locale="en"
                                                reset-button label-reset-button="Clear" :date-format-options="{
                                                    year: 'numeric',
                                                    month: 'short',
                                                    day: '2-digit',
                                                    weekday: 'short',
                                                }" :date-disabled-fn="dateFromDisabled" v-validate="'required'" />
                                            <span v-show="errors.has('Date To')" class="help-block">
                                                {{ errors.first('Date To') }}
                                            </span>
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <b-row no-gutters>
                                    <b-col lg="4" md="6" sm="12" class="mr-4">
                                        <b-form-group label="Region">
                                            <v-select class="style-chooser" label="text"
                                                :options="filterByOptions.regionItems"
                                                :reduce="(region) => region.value" v-model="filterBy.region">
                                                <template v-slot:no-options="{ search, searching }">
                                                    <template v-if="searching">
                                                        No results found for
                                                        <em>
                                                            <strong>{{ search }}</strong>
                                                        </em>
                                                    </template>
                                                    <em :style="{ opacity: 0.5 }" v-else>
                                                        Start typing to search for region
                                                    </em>
                                                </template>
                                            </v-select>
                                        </b-form-group>
                                    </b-col>
                                    <b-col lg="4" md="6" sm="12" class="mr-4">
                                        <b-form-group label="Area">
                                            <v-select class="style-chooser" label="text"
                                                :options="filterByOptions.areaItems" :reduce="(area) => area.value"
                                                v-model="filterBy.area">
                                                <template v-slot:no-options="{ search, searching }">
                                                    <template v-if="searching">
                                                        No results found for
                                                        <em>
                                                            <strong>{{ search }}</strong>
                                                        </em>
                                                    </template>
                                                    <em :style="{ opacity: 0.5 }" v-else>
                                                        Start typing to search for area
                                                    </em>
                                                </template>
                                            </v-select>
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <b-row no-gutters>
                                    <b-col lg="4" md="6" sm="12" class="mr-4">
                                        <b-form-group label="Branch Code"
                                            description="NOTE: Input the exact branch code to search">
                                            <b-form-input id="branchCode" name="Branch Code" type="search"
                                                class="numFont" v-model="filterBy.branchCode"
                                                v-validate="getValidationParam(false, branchCodeRegex)" />
                                            <span v-show="errors.has('Branch Code')" class="help-block">
                                                {{ errors.first('Branch Code') }}
                                            </span>
                                        </b-form-group>
                                    </b-col>
                                    <b-col lg="4" md="6" sm="12" class="mr-4">
                                        <b-form-group label="Company">
                                            <v-select class="style-chooser" label="text"
                                                :options="filterByOptions.companyItems"
                                                :reduce="(company) => company.value" v-model="filterBy.company">
                                                <template v-slot:no-options="{ search, searching }">
                                                    <template v-if="searching">
                                                        No results found for
                                                        <em>
                                                            <strong>{{ search }}</strong>
                                                        </em>
                                                    </template>
                                                    <em :style="{ opacity: 0.5 }" v-else>
                                                        Start typing to search for a company
                                                    </em>
                                                </template>
                                            </v-select>
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <b-row no-gutters>
                                    <b-col lg="4" md="6" sm="12" class="mr-4">
                                        <b-form-group label="Status">
                                            <v-select class="style-chooser" label="text"
                                                :options="filterByOptions.statusItems"
                                                :reduce="(status) => status.value" v-model="filterBy.status">

                                                <template v-slot:no-options="{ search, searching }">
                                                    <template v-if="searching">
                                                        No results found for
                                                        <em>
                                                            <strong>{{ search }}</strong>
                                                        </em>
                                                    </template>
                                                    <em :style="{ opacity: 0.5 }" v-else>
                                                        Start typing to search for status
                                                    </em>
                                                </template>
                                            </v-select>
                                        </b-form-group>
                                    </b-col>

                                    <b-col lg="4" md="6" sm="12" class="mr-4">
                                        <b-form-group label="Ticket Id"
                                            description="NOTE: Input the exact ticket id to search">
                                            <b-form-input id="ticketId" name="Ticket Id" type="search" class="numFont"
                                                v-model="filterBy.ticketId"
                                                v-validate="getValidationParam(false, /^TR\d{13}$/)"
                                                placeholder="TRXXXXXXXXXXXXX" />
                                            <span v-show="errors.has('Ticket Id')" class="help-block">
                                                {{ errors.first('Ticket Id') }}
                                            </span>
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <b-row no-gutters>
                                    <b-col sm="12">
                                        <b-button class="mr-1" variant="success" @click="onFilterRequest">
                                            Search
                                        </b-button>
                                        <b-button class="mr-1" variant="primary" @click="resetFilters">
                                            Reset
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-collapse>
                    </b-col>
                </b-row>

                <!-- Select Actions and Items Per Page Options -->
                <b-row>
                    <b-col sm="6" md="3" class="mt-4 mb-2">
                        <b-dropdown text=" Select Actions " variant="dark" slot="append">
                            <b-dropdown-item>
                                <json-excel :data="exportData" :fields="exportFields" type="xls"
                                    :name="fileName + '.xls'">
                                    Export User Tickets in Excel
                                </json-excel>
                            </b-dropdown-item>
                            <b-dropdown-item>
                                <json-excel :data="exportData" :fields="exportFields" type="csv"
                                    :name="fileName + '.csv'">
                                    Export User Tickets to CSV
                                </json-excel>
                            </b-dropdown-item>
                        </b-dropdown>
                    </b-col>

                    <b-col sm="6" md="4" offset-md="5" class="mt-4 mb-2 text-md-right">
                        <b-input-group prepend="Show" append="/ Page">
                            <b-form-select :options="pageOptions" v-model="perPage" />
                        </b-input-group>
                    </b-col>
                </b-row>

                <b-table ref="ticketsTable" show-empty striped hover :items="items" :fields="fields"
                    :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc" :sort-direction="sortDirection" responsive>

                    <template v-slot:cell(ticketId)="row">
                        <span class="numFont">
                            {{ row.item.ticketId }}
                        </span>
                    </template>

                    <template v-slot:cell(name)="row">
                        {{ row.item.name }}
                    </template>

                    <template v-slot:cell(dateCreated)="row">{{
                        row.item.dateCreated
                            ? getFormattedDateWithTime(row.item.dateCreated)
                            : '-'
                    }}</template>

                    <template v-slot:cell(dateResolved)="row">{{
                        row.item.dateResolved
                            ? getFormattedDateWithTime(row.item.dateResolved)
                            : '-'
                    }}</template>

                    <template v-slot:cell(duration)="row">
                        {{ row.item.duration }}
                    </template>

                    <template v-slot:cell(status)="row">
                        <UserTicketStatus :ticket="row.item" />
                    </template>

                    <template v-slot:cell(actions)="row">
                        <UserTicketRowActions :row="row" :isSuperAdmin="isSuperAdmin" :isViewer="isViewer" />
                    </template>

                    <template slot="row-details" slot-scope="row">
                        <UserTicketDetailsView :row="row" />
                    </template>
                </b-table>

                <b-row>
                    <b-col md="8" sm="12" class="my-1">
                        <span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
                    </b-col>
                    <b-col md="4" sm="12" class="my-1">
                        <b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
                            class="my-0" />
                    </b-col>
                </b-row>
            </b-container>
        </b-card>

        <!-- Modals -->
        <UserTicketUpdateDialog />
    </div>
</template>

<script>
// Components
import UserTicketStatus from './userTickets/UserTicketStatus.vue';
import UserTicketRowActions from './userTickets/UserTicketRowActions.vue';
import UserTicketDetailsView from './userTickets/UserTicketDetailsView.vue';
import UserTicketUpdateDialog from './userTickets/UserTicketUpdateDialog.vue'

// Util
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { DateUtil } from '@/utils/dateutil';

// Database
import ticketDAO from '@/database/tickets';

// Others
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import JsonExcel from 'vue-json-excel';
import moment from 'moment';
import _ from 'lodash';
import EventBus from '../../shared/event-bus';

export default {
    name: 'user-tickets',
    components: {
        UserTicketStatus,
        UserTicketRowActions,
        UserTicketDetailsView,
        UserTicketUpdateDialog,
        Loading,
        JsonExcel,
    },
    data() {
        return {
            items: [],
            fields: [
                {
                    key: 'ticketId',
                    sortable: true,
                },
                {
                    key: 'name',
                    sortable: true,
                },
                {
                    key: 'dateCreated',
                    sortable: true,
                },
                {
                    key: 'dateResolved',
                    sortable: true,
                },
                {
                    key: 'duration',
                    sortable: false,
                },
                {
                    key: 'requestType',
                    sortable: false,
                },
                {
                    key: 'status',
                    sortable: false,
                },
                {
                    key: 'actions',
                    sortable: false,
                    thClass: 'text-center'
                }
            ],
            currentPage: 1,
            perPage: 10,
            totalRows: 0,
            pageOptions: [5, 10, 15, 25, 50, 100],
            sortBy: null,
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,

            allRegionsObj: {},
            allAreasObj: {},
            allCompaniesObj: {},
            allTicketsObj: {},

            filterByOptions: {
                regionItems: [],
                areaItems: [],
                companyItems: [],
                statusItems: config.tcketStatusOptions,
            },

            defaultFilterBy: {
                dateFrom: moment().format('YYYY-MM-DD'),
                dateTo: moment().format('YYYY-MM-DD'),
                region: { ...config.dropdownDefaultValue },
                area: { ...config.dropdownDefaultValue },
                branchCode: null,
                company: { ...config.companyDefaultValue },
                status: null,
                ticketId: null
            },
            filterBy: {
                dateFrom: moment().format('YYYY-MM-DD'),
                dateTo: moment().format('YYYY-MM-DD'),
                region: { ...config.dropdownDefaultValue },
                area: { ...config.dropdownDefaultValue },
                branchCode: null,
                company: { ...config.companyDefaultValue },
                status: null,
                ticketId: null
            },
            prevFilter: {},

            isSuperAdmin: this.$store.getters.isSuperAdmin,
            isViewer: this.$store.getters.isViewer,
            loggedUserCompany: this.$store.getters.loggedUserCompany,
            loggedUser: this.$store.getters.loggedUser,
            // Check for loader
            isLoading: false,

            // Listener
            ticketListener: null,
        };
    },
    computed: {
        /**
         * Returns the set of data to be included in the export. For now this just
         * returns the data as is.
         *
         * @returns {Array} the set of data to be included in the export.
         */
        exportData() {
            return this.items;
        },

        /**
         * Derives the field information based from the data table configuration.
         *
         * @returns {object} the fields to be included in the export.
         */
        exportFields() {
            return {
                'Ticket ID': 'ticketId',
                'Request Type': 'requestType',
                'First Name': 'userDetails.firstName',
                'Middle Name': 'userDetails.middleName',
                'Last Name': 'userDetails.lastName',
                'Employee No': 'userDetails.employeeNo',
                'Position': 'userDetails.position',
                'Contact No': 'userDetails.contactNo',
                'Region': 'branchDetails.region',
                'Area': 'branchDetails.area',
                'Branch Code': 'branchDetails.branchCode',
                'Other Concerns': 'otherConcerns',
                'Status': 'status',
                'Date Created': 'Date Created',
                'Date Resolved': 'Date Resolved',
                'Resolved By': 'resolvedBy',
                'Duration': 'duration',
                'Date Cancelled': 'Date Cancelled',
                'Cancelled By': 'cancelledBy',
            };
        },

        fileName() {
            let currTimeStamp = DateUtil.getCurrentTimestamp();
            return 'UserTickets-' + DateUtil.getDateInDDMMYYYYHHSSFormat(currTimeStamp);
        },

        branchCodeRegex() {
            return config.branchCodeRegex;
        }
    },
    watch: {
        "filterBy.region": function (newVal) {
            if (newVal !== null) {
                let filteredAreasObj = _.filter(this.allAreasObj, o => {
                    return o.region === newVal;
                });
                this.filterByOptions.areaItems = DropDownItemsUtil.retrieveAreaItems(filteredAreasObj);
            } else {
                this.filterByOptions.areaItems = DropDownItemsUtil.retrieveAreaItems(this.allAreasObj);
            }

            // reset area
            this.filterBy.area = null;
        }
    },
    mounted() {
        setTimeout(async () => {
            try {
                // Don't initiate data retrieval when the account is not authenticated
                if (!this.$store.getters.isAuthenticated) {
                    return;
                }

                // show loading indicator
                this.isLoading = true;

                // Load initial data
                this.allRegionsObj = this.$store.getters.regions;
                this.filterByOptions.regionItems = DropDownItemsUtil.retrieveRegionItems(this.allRegionsObj);

                this.allAreasObj = this.$store.getters.areas;
                this.filterByOptions.areaItems = DropDownItemsUtil.retrieveAreaItems(this.allAreasObj);

                this.allCompaniesObj = { ...this.$store.getters.companies };
                this.filterByOptions.companyItems = DropDownItemsUtil.retrieveCompanyItems(this.allCompaniesObj);

                await this.retrieveData();
            } catch (error) {
                this.$toaster.error('Error loading data. Please reload the page again.');
            }

            // hide loading indicator
            this.isLoading = false;
        }, config.timeout);

        EventBus.$on('onCloseSaveTicket', ticket => {
            this.updateTable(ticket);
        });
    },
    methods: {
        getValidationParam(isRequired, regex) {
            return {
                required: isRequired,
                regex: regex,
            };
        },
        listenerCallback(type, ticket) {
            if ((!this.allTicketsObj[ticket.id] && type === "added") || type === "modified") {
                this.allTicketsObj[ticket.id] = ticket;
                this.filterTickets(this.allTicketsObj);
            }
        },
        updateTable(ticket) {
            if (!_.isEmpty(ticket)) {
                this.allTicketsObj[ticket.id] = ticket;
                this.filterTickets(this.allTicketsObj);
            }
        },

        dateFromDisabled(_ymd, date) {
            return date > new Date();
        },
        validateFilter() {
            let isValid = true;

            if (_.isEmpty(this.filterBy.dateFrom) && _.isEmpty(this.filterBy.dateTo)) {
                this.$toaster.warning('Date From and Date To are required.');
                isValid = false;
            } else if ((_.isEmpty(this.filterBy.dateFrom) && !_.isEmpty(this.filterBy.dateTo)) ||
                (!_.isEmpty(this.filterBy.dateFrom) && _.isEmpty(this.filterBy.dateTo))) {
                this.$toaster.warning('Invalid Date Range. Date From and Date To must both have value.');
                isValid = false;
            } else if (this.filterBy.dateFrom > this.filterBy.dateTo) {
                this.$toaster.warning('Invalid Date Range. Date From must be less than Date To.');
                isValid = false;
            } else if (DateUtil.getNoOfDays(this.filterBy.dateFrom, this.filterBy.dateTo) > 90) {
                this.$toaster.warning('Invalid Date Range. Data range is allowed up to 90 days difference.');
                isValid = false;
            }

            return isValid;
        },

        async onFilterRequest() {
            if (!this.validateFilter()) {
                return;
            }

            if (!_.isEqual(this.filterBy, this.prevFilter)) {
                await this.retrieveData();
                this.prevFilter = { ...this.filterBy };
            }
        },
        resetFilters() {
            if (!_.isEqual(this.filterBy, this.defaultFilterBy)) {
                // reset to default
                this.filterBy = { ...this.defaultFilterBy };
                this.prevFilter = { ...this.filterBy };
            }
        },
        getFilterBy() {
            let filter = { ...this.filterBy };

            filter.fromTimestamp = DateUtil.startDateTimeStamp(new Date(filter.dateFrom));
            filter.toTimestamp = DateUtil.endDateTimeStamp(new Date(filter.dateTo));

            let region = filter.region ? filter.region : {};
            if (region && typeof region === 'string') {
                filter.regionName = this.allRegionsObj[region].name;
            }

            let area = filter.area ? filter.area : {};
            if (area && typeof area === 'string') {
                let areaObj = _.find(this.allAreasObj, o => {
                    return o.shortName === area;
                });
                filter.areaName = areaObj ? areaObj.name : '';
            }

            return filter;
        },
        async retrieveData() {
            try {
                // Show loader
                this.isLoading = true;

                let filter = this.getFilterBy();
                let results = await ticketDAO.getTickets(filter);

                if (results.isSuccess) {
                    this.allTicketsObj = results.tickets;
                } else {
                    this.allTicketsObj = {};
                    this.$toaster.error('Error loading data. Please reload the page again.');
                }

                this.filterTickets(this.allTicketsObj);

                // update listener
                this.ticketListener = ticketDAO.getTicketListener(filter, this.listenerCallback);

            } catch (error) {
                this.$toaster.error('Error loading data. Please reload the page again.');
            }

            // hide loading indicator
            this.isLoading = false;
        },
        filterTickets(allTicketsObj) {
            let filteredObjs = { ...allTicketsObj };

            _.forEach(filteredObjs, (ticket, id) => {
                let region = this.filterBy.regionName;
                if (region && typeof region === 'string' && region !== ticket.branchDetails.region) {
                    delete filteredObjs[id];
                }

                let area = this.filterBy.areaName;
                if (area && typeof area === 'string' && area !== ticket.branchDetails.area) {
                    delete filteredObjs[id];
                }

                let branchCode = this.filterBy.branchCode;
                if (branchCode && typeof branchCode === 'string' && branchCode !== ticket.branchDetails.branchCode) {
                    delete filteredObjs[id];
                }

                if (this.filterBy.status !== null && this.filterBy.status !== ticket.status) {
                    delete filteredObjs[id];
                }

                if (this.filterBy.ticketId !== null && this.filterBy.ticketId !== ticket.ticketId) {
                    delete filteredObjs[id];
                }
            });

            this.processTickets(filteredObjs);
        },
        processTickets(tickets) {
            this.allTicketsObj = tickets;

            this.items = Object.values(this.allTicketsObj);
            this.items.forEach((item) => {
                item['Date Created'] = this.getFormattedDateWithTime(item.dateCreated);
                item['Date Cancelled'] = this.getFormattedDateWithTime(item.dateCancelled);

                if (item.status === 'Done') {
                    item['Date Resolved'] = this.getFormattedDateWithTime(item.dateResolved);
                    item['resolvedBy'] = item.resolvedBy;
                    item['duration'] = this.getDuration(item);
                } else {
                    item['Date Resolved'] = '-';
                    item['resolvedBy'] = '-';
                    item['duration'] = '-';
                }

                item['name'] = this.getUserDisplay(item);
            });
            this.totalRows = this.items.length;

            // remove show details
            _.forEach(this.items, item => {
                delete item._showDetails;
            });

            // refresh table
            if (this.$refs.ticketsTable) {
                this.$refs.ticketsTable.refresh();
            }
        },

        // UTILS
        getUserDisplay(item) {
            let name = '';

            if (item.userDetails) {
                let user = item.userDetails;
                name += user.firstName && user.firstName.length > 0 ? user.firstName : '';
                name += user.middleName && user.middleName.length > 0 ? ' ' + user.middleName : '';
                name += user.lastName && user.lastName.length > 0 ? ' ' + user.lastName : '';
            }

            return name;
        },
        getFormattedDateWithTime(date) {
            if (date) {
                return DateUtil.getFormattedDateWithTime(date);
            }
            return '-';
        },
        getDuration(item) {
            if (item.status === 'Done') {
                return DateUtil.displayTimeDifference(item.dateCreated, item.dateResolved);
            } else if (item.status === 'Cancelled') {
                return DateUtil.displayTimeDifference(item.dateCreated, item.dateCancelled);
            }
            return '-';
        }
    },
    beforeUnmount() {
        if (this.ticketListener != null) {
            // Stop listening to changes
            this.ticketListener();
        }
    },
};
</script>

<style scoped>
.filter {
    background-color: white;
    border-color: #122c91;
}

.filter:hover {
    background-color: #122c91;
    color: white;
}
</style>
