import { render, staticRenderFns } from "./UserTickets.vue?vue&type=template&id=2f5e92a4&scoped=true&"
import script from "./UserTickets.vue?vue&type=script&lang=js&"
export * from "./UserTickets.vue?vue&type=script&lang=js&"
import style0 from "./UserTickets.vue?vue&type=style&index=0&id=2f5e92a4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f5e92a4",
  null
  
)

export default component.exports